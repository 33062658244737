import React, { useState } from "react"
import PropTypes from 'prop-types'
import { FormattedMessage } from "react-intl"

import Seo from '../seo'
import Layout from "../layout"
import VulnerabilityManagement from './vulnerabilityManagement'
import List from './list'

import '../../style/security.scoped.scss'

const PageCpt = ({ activeKey, pageLang }) => {
  const pagePrefix = pageLang === 'en' ? '/en' : ''

  const [pageActiveKey, setActiveKey] = useState(activeKey)
  return (
    <Layout pageLang={pageLang} showHeader={true} showFooter={true} useTransparentTheme={true} customClass="use-translucent-theme">
      <Seo title="MogDB: Security" />
      <div className="w-banner-container">
        <div className="w-banner-box"><div className="w-banner-text"><FormattedMessage id="secutity.pageTitle" /></div></div>
      </div>
      <div className="security-container">
        <div className="tabs-box">
          <div className={`tab-item ${pageActiveKey === '1' ? 'is-active' : ''}`} onClick={() => setActiveKey('1')}><FormattedMessage id="secutity.tab1" /></div>
          <div className={`tab-item ${pageActiveKey === '2' ? 'is-active' : ''}`} onClick={() => setActiveKey('2')}><FormattedMessage id="secutity.tab2" /></div>
          <div className={`tab-item ${pageActiveKey === '3' ? 'is-active' : ''}`} onClick={() => setActiveKey('3')}>CVE</div>
        </div>
        {pageActiveKey === '1' && <VulnerabilityManagement pageLang={pageLang} />}
        {pageActiveKey === '2' && <List pagePrefix={pagePrefix} type="ADVISORY" pageLang={pageLang} />}
        {pageActiveKey === '3' && <List pagePrefix={pagePrefix} type="CVE" pageLang={pageLang} />}
      </div>
    </Layout>
  )
}

PageCpt.propTypes = {
  activeKey: PropTypes.string
}
export default PageCpt