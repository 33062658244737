import React from "react"

import PageCpt from '../components/security/pageCpt'

const Security = ({ pageContext }) => {
  const pageLang = pageContext.lang
  return (
    <PageCpt pageLang={pageLang} activeKey="3"/>
  )
}
export default Security