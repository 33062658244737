import React from "react"
import { FormattedMessage } from "react-intl"
import '../../style/security.scoped.scss'
const VulnerabilityManagement =({ pageLang }) => (
  <div className="security-box">
    <h3 id="1-漏洞响应"><FormattedMessage id="vulnerability.t1" /></h3>
    <p><FormattedMessage id="vulnerability.p1" /></p>
    <h3 id="2-支持版本"><FormattedMessage id="vulnerability.t2" /></h3>
    <p><FormattedMessage id="vulnerability.p2" /></p>
    <h3 id="3-漏洞处理流程"><FormattedMessage id="vulnerability.t3" /></h3>
    <p><FormattedMessage id="vulnerability.p3-1" /></p>
    <p>
      <img src={pageLang === 'en' ? '/vulnerability_en.png' : '/vulnerability.png'} width="100%" />
    </p>
    <p><FormattedMessage id="vulnerability.p3-2" /></p>
    <h4 id="3-1-漏洞上报"><FormattedMessage id="vulnerability.t3_1" /></h4>
    <p><FormattedMessage id="vulnerability.p3_1" /></p>
    <h5 id="3-1-1-漏洞上报方式"><FormattedMessage id="vulnerability.t3_1_1" /></h5>
    <p><FormattedMessage id="vulnerability.p3_1_1-1" /><a className="link-text" href="mailto:mogdb-psirt@enmotech.com"> mogdb-psirt@enmotech.com</a><FormattedMessage id="vulnerability.p3_1_1-2" /></p>
    <h5 id="3-1-2-漏洞上报内容"><FormattedMessage id="vulnerability.t3_1_2" /></h5>
    <p><FormattedMessage id="vulnerability.p3_1_2" /></p>
    <ul>
      <li><p><FormattedMessage id="vulnerability.p3_1_2-1" /></p></li>
      <li><p><FormattedMessage id="vulnerability.p3_1_2-2" /></p></li>
      <li><p><FormattedMessage id="vulnerability.p3_1_2-3" /></p></li>
      <li><p><FormattedMessage id="vulnerability.p3_1_2-4" /></p></li>
      <li><p><FormattedMessage id="vulnerability.p3_1_2-5" /></p></li>
    </ul>
    <h5 id="3-1-3-邮件响应时间"><FormattedMessage id="vulnerability.t3_1_3" /></h5>
    <p><FormattedMessage id="vulnerability.p3_1_3" /></p>
    <h4 id="3-2-漏洞严重性评估"><FormattedMessage id="vulnerability.t3_2" /></h4>
    <p><FormattedMessage id="vulnerability.p3_2" /></p>
    <h5 id="3-2-1-评估标准"><FormattedMessage id="vulnerability.t3_2_1" /></h5>
    <p><FormattedMessage id="vulnerability.p3_2_1-1" /></p>
    <ul>
      <li><p><FormattedMessage id="vulnerability.p3_2_1-2" /></p></li>
      <li><p><FormattedMessage id="vulnerability.p3_2_1-3" /></p></li>
      <li><p><FormattedMessage id="vulnerability.p3_2_1-4" /></p></li>
      <li><p><FormattedMessage id="vulnerability.p3_2_1-5" /></p></li>
      <li><p><FormattedMessage id="vulnerability.p3_2_1-6" /></p></li>
      <li><p><FormattedMessage id="vulnerability.p3_2_1-7" /></p></li>
      <li><p><FormattedMessage id="vulnerability.p3_2_1-8" /></p></li>
      <li><p><FormattedMessage id="vulnerability.p3_2_1-9" /></p></li>
    </ul>
    <h5 id="3-2-2-评估原则"><FormattedMessage id="vulnerability.t3_2_2" /></h5>
    <ul>
      <li><p><FormattedMessage id="vulnerability.p3_2_2-1" /></p></li>
      <li><p><FormattedMessage id="vulnerability.p3_2_2-2" /></p></li>
      <li><p><FormattedMessage id="vulnerability.p3_2_2-3" /></p></li>
      <li><p><FormattedMessage id="vulnerability.p3_2_2-4" /></p></li>
      <li><p><FormattedMessage id="vulnerability.p3_2_2-5" /></p></li>
    </ul>
    <h5 id="3-2-3-评估步骤"><FormattedMessage id="vulnerability.t3_2_3" /></h5>
    <p><FormattedMessage id="vulnerability.p3_2_3-1" /></p>
    <ul>
      <li><p><FormattedMessage id="vulnerability.p3_2_3-2" /></p></li>
      <li><p><FormattedMessage id="vulnerability.p3_2_3-3" /></p></li>
      <li><p><FormattedMessage id="vulnerability.p3_2_3-4" /></p></li>
    </ul>
    <h5 id="3-2-4-严重等级划分"><FormattedMessage id="vulnerability.t3_2_4" /></h5>
    <table border="2" className="table table-bordered table-striped">
      <thead>
        <tr>
          <th align="left"><FormattedMessage id="vulnerability.p3_2_4-1" /></th>
          <th align="left"><FormattedMessage id="vulnerability.p3_2_4-2" /></th>
        </tr>
        <tr>
          <td align="left"><FormattedMessage id="vulnerability.p3_2_4-3" /></td>
          <td>9.0 - 10.0</td>
        </tr>
        <tr>
          <td align="left"><FormattedMessage id="vulnerability.p3_2_4-4" /></td>
          <td>7.0 - 8.9</td>
        </tr>
        <tr>
          <td align="left"><FormattedMessage id="vulnerability.p3_2_4-5" /></td>
          <td>4.0 - 6.9</td>
        </tr>
        <tr>
          <td align="left"><FormattedMessage id="vulnerability.p3_2_4-6" /></td>
          <td>0.1 - 3.9</td>
        </tr>
        <tr>
          <td align="left"><FormattedMessage id="vulnerability.p3_2_4-7" /></td>
          <td>0.0</td>
        </tr>
      </thead>
    </table>
    <h4 id="3-3-漏洞披露"><FormattedMessage id="vulnerability.t3_3" /></h4>
    <p><FormattedMessage id="vulnerability.p3_3" /></p>
  </div>
)
export default VulnerabilityManagement