import React, { useState, useEffect } from "react"
import { navigate } from 'gatsby'
import { FormattedMessage } from "react-intl"
import axios from 'axios'
import { Table, message, Pagination } from 'antd'

import '../../style/security.scoped.scss'


import { getParams, timeFormat } from '../../utils/methods'

const TypesData = {
  Headers: {
    ADVISORY: 'header.securityAdvisories',
    CVE: 'header.securityCve'
  },
  Columns: {
    ADVISORY: ['name', 'summary', 'level', 'version', 'component', 'releaseDate'],
    CVE: ['name', 'summary', 'score', 'releaseDate', 'updatedTime']
  },
  ColumnsWidth: {
    ADVISORY: [],
    CVE: [160, '', 130, 180, 210]
  },
  MobileColumns: {
    ADVISORY: ['desc', 'level', 'version', 'component', 'releaseDate'],
    CVE: ['summary', 'score', 'releaseDate', 'updatedTime']
  }
}

const Advisories = ({ pagePrefix, type, pageLang }) => {
  const goDetail = (record) => {
    navigate(`${pagePrefix}/${type.toLowerCase()}/detail?id=${record.id}`)
  }

  const apiPrefix = process.env && process.env.NODE_ENV === 'development' ? 'http://jenkins.mogdb.enmotech.com:7004/api' : '/api'
  const [params, setParams] = useState({
    pageNum: 1,
    pageLimit: 10,
    search: '',
    status: 1,
    order: 'releaseDate',
    desc: true,
    type
  })
  const [data, setData] = useState({ total: 0, list: [] })
  const [loading, setLoading] = useState(false);
  useEffect(async () => {
    setLoading(true)
    const res = await axios.get(apiPrefix + '/public/security', { params });
    setLoading(false)
    if (res && res.data && res.data.code === 200) {
      const result = res.data.data || {}
      setData(result || { total: 0, list: [] })
    } else {
      message.error(res.data && res.data.message || 'error');
    }
  }, [params]);

  const acceptParams = (type, val) => {
    const newParams = getParams(params, type, val)
    setParams(prev => ({ ...prev, ...newParams }))
  }

  const columns = TypesData.Columns[type].map((item, idx) => {
    const colItem = {
      title: <FormattedMessage id={`${type === 'CVE' ? 'cve' : 'advisories'}.tableCol${idx + 1}`} />,
      dataIndex: item,
      key: item,
      width: TypesData.ColumnsWidth[type][idx] || 'auto'
    }
    if (item === 'name') {
      colItem.render = (text, record) => <span className="link-text" onClick={() => goDetail(record)}>{text}</span>
    }
    if (item === 'updatedTime') {
      colItem.render = (text) => timeFormat(text, 'YYYY-MM-DD HH:mm:ss')
    }
    if (item === 'summary') {
      colItem.render = (text, record) => <div className="summary">{(record[pageLang === 'zh' ? 'summaryZh' : 'summary'] || record.summary).replaceAll('<br/>', '')}</div>
    }
    return colItem
  })
  return (
    <div className="security-box">
      <h2 className="pc-title">MogDB <FormattedMessage id={TypesData.Headers[type]} /></h2>
      <div className="pc-table">
        <Table rowKey="id" loading={loading} columns={columns} dataSource={data.list} pagination={false} />
        <Pagination
          hideOnSinglePage
          className="pagination-right"
          total={data.total}
          showTotal={num => <><FormattedMessage id="secutity.total" /> {num} <FormattedMessage id="secutity.item" /></>}
          pageSize={params.pageLimit}
          current={params.pageNum}
          defaultCurrent={params.pageNum}
          onChange={val => acceptParams('curPage', val)}
        />
      </div>
      <div className="mobile-table-box">
        {data.list.map((item, idx) => (
          <div className="mobile-table" key={idx}>
            <div className="m-table-title"><div className="title">{idx + 1}.<FormattedMessage id={`${type === 'CVE' ? 'cve' : 'advisories'}.tableCol1`} />：</div><span className="link-text" onClick={() => goDetail(item)}>{item.name}</span></div>
            {TypesData.MobileColumns[type].map((col, i) => (
              <div className="m-table-row" key={i}>
                <div className="m-table-header"><FormattedMessage id={`${type === 'CVE' ? 'cve' : 'advisories'}.tableCol${i + 2}`} /></div>
                <div className="m-table-content">{item[col]}</div>
              </div>
            ))}
          </div>
        ))}
        <Pagination
          hideOnSinglePage
          size="small"
          className="pagination-right"
          total={data.total}
          pageSize={params.pageLimit}
          current={params.pageNum}
          defaultCurrent={params.pageNum}
          onChange={val => acceptParams('curPage', val)}
        />
      </div>
    </div>
  )
}
export default Advisories